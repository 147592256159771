import type { LinksFunction, MetaFunction } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "@remix-run/react";
import styles from "./tailwind.css";
import sassyStyles from "../node_modules/react-datepicker/dist/react-datepicker.min.css";
import { Apptheme } from "./routes/components/constVar";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: sassyStyles },
  ];
};

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "GOODMOVE Consolidation Web",
  viewport: "width=device-width,initial-scale=1",
});

export default function App() {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
        </style>
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}
